import * as influence from "influence-utils";
import { jobs } from "../data/rarityData.json";

//------------------------------------------------------------------------------------------------
// Helper functions
//------------------------------------------------------------------------------------------------
// Number padding
export function zeroPad(nr, base) {
    var len = String(base).length - String(nr).length + 1;
    return Number(len > 0 ? new Array(len).join("0") + nr : nr);
}

export function zeroPad2(nr, base) {
    var len = base - String(nr).length + 1;
    return len > 0 ? new Array(len).join("0") + nr : nr;
}

// Rounding
export function decimalClip(number, zeros) {
    return Number(parseFloat(number).toPrecision(zeros));
}

// Rounding
export function roundOff(number, zeros) {
    return Math.round(number * Math.pow(10, zeros)) / Math.pow(10, zeros);
}

// Normalise data to min/max range
export function normalise(val, min, max) {
    return (val - min) / (max - min);
}

export function radsToDegs(rad) {
    return (rad * 180) / Math.PI;
}

export function degsToRads(deg) {
    return deg * (Math.PI / 180);
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// Add thousand seperator
export function addCommas(n) {
    var rx = /(\d+)(\d{3})/;
    return String(n).replace(/^\d+/, function (w) {
        while (rx.test(w)) {
            w = w.replace(rx, "$1,$2");
        }
        return w;
    });
}

// Copy to clipboard
export function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
}

export function truncateAddress(address) {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
}

export function timestampDate(timestamp) {
    const date_obj = new Date(timestamp * 1000);
    const date = ("0" + date_obj.getDate()).slice(-2);
    const hrs = ("0" + date_obj.getHours()).slice(-2);
    const mins = ("0" + date_obj.getMinutes()).slice(-2);
    //const sec = ("0" + date_obj.getSeconds()).slice(-2);
    //const yr = date_obj.getFullYear();
    const mth = ("0" + (date_obj.getMonth() + 1)).slice(-2);
    return date + "/" + mth + " @ " + hrs + ":" + mins;
}

export function dateFormat(dateObj) {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let day = days[dateObj.getDay()];
    let date = dateObj.getDate();
    let dateDigit = String(date).slice(-1);
    let datePostfix;
    switch (dateDigit) {
        case "1":
            datePostfix = "st";
            break;
        case "2":
            datePostfix = "nd";
            break;
        case "3":
            datePostfix = "rd";
            break;
        default:
            datePostfix = "th";
    }

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let month = months[dateObj.getMonth()];
    let year = dateObj.getFullYear();

    return (
        <div>
            {day}, {date}
            <small>
                <sup>{datePostfix}</sup>
            </small>{" "}
            {month} {year}
        </div>
    );
}

export function dateFormatShort(dateObj) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let day = days[dateObj.getDay()];
    let date = dateObj.getDate();
    let datePostfix;
    switch (date) {
        case "1":
            datePostfix = "st";
            break;
        case "2":
            datePostfix = "nd";
            break;
        case "3":
            datePostfix = "rd";
            break;
        case "21":
            datePostfix = "st";
            break;
        case "22":
            datePostfix = "nd";
            break;
        case "23":
            datePostfix = "rd";
            break;
        case "31":
            datePostfix = "st";
            break;
        case "32":
            datePostfix = "nd";
            break;
        case "33":
            datePostfix = "rd";
            break;
        default:
            datePostfix = "th";
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month = months[dateObj.getMonth()];
    let year = dateObj.getFullYear();

    return (
        <div>
            {day}, {date}
            <small>
                <sup>{datePostfix}</sup>
            </small>{" "}
            {month} {year}
        </div>
    );
}

export function distance(x1, x2, y1, y2, z1, z2) {
    return Math.sqrt((x2 -= x1) * x2 + (y2 -= y1) * y2 + (z2 -= z1) * z2);
}

export function clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
}
// =======================================
// Influence helpers
// =======================================
export const apiOffsets = {
    collection: 1,
    crewClass: 1,
    title: 1,
    sex: 1,
    body: 1,
    outfit: 1,
    hairstyle: 0,
    hairColour: 1,
    facial: 0,
    headwear: 0,
    bonus: 0,
    traits: 1,
};

// |---CREW
// =======================================
// Convert class id to string
export function crewDescription(crewMember) {
    return `A ${influence.toCrewSex(crewMember.id_sex)} ${influence.toCrewClass(crewMember.id_class)} and former ${influence.toCrewCollection(crewMember.id_collection)} (${influence.toCrewTitle(crewMember.id_title)})`;
}

// Convert collection id to string
export function collectionName(id_collection) {
    return influence.toCrewCollection(id_collection);
}

// Convert class id to string
export function className(id_class) {
    return influence.toCrewClass(id_class);
}

// Colour for classes
export function classColours(crewClass) {
    if (crewClass === "Pilot") {
        return "#884fff";
    } else if (crewClass === "Engineer") {
        return "#de4200";
    } else if (crewClass === "Miner") {
        return "#fe984e";
    } else if (crewClass === "Merchant") {
        return "#fed84f";
    } else if (crewClass === "Scientist") {
        return "#4f90ff";
    }
}

// Convert class id to string
export function titleName(id_title) {
    return influence.toCrewTitle(id_title);
}

// Convert class id to string
export function titleTier(id_title) {
    const title = influence.toCrewTitle(id_title);
    return jobs.Title[title].tier;
}

// Convert class id to string
export function titleDept(id_title) {
    const title = influence.toCrewTitle(id_title);
    return jobs.Title[title].department;
}

export function departmentColours(department) {
    return classColours(jobs.department[department].class);
}

// Convert title id to department string
export function titlePurpose(id_title) {
    const title = influence.toCrewTitle(id_title);
    return jobs.department[jobs.Title[title].department].purpose;
}

// Convert title id to department efficiencies
export function titleEfficiency(id_title) {
    const title = influence.toCrewTitle(id_title);
    return jobs.department[jobs.Title[title].department].efficiency;
}

// Convert class id to string
export function classDescription(id_class) {
    return influence.toCrewClassDescription(id_class);
}

// Colour for classes
export function classActions(crewClass) {
    if (crewClass === "Pilot") {
        return ["Ship Flight"];
    } else if (crewClass === "Engineer") {
        return ["Building construction", "Building operation"];
        //return ["Refinery operation", "Factory operation", "Shipyard operation", "Building construction"];
    } else if (crewClass === "Miner") {
        return ["Core sampling", "Resource extraction"];
    } else if (crewClass === "Merchant") {
        return ["Market trading", "Surface transport"];
    } else if (crewClass === "Scientist") {
        return ["Food production", "Food consumption"];
    }
}

// |---ASTEROIDS
// =======================================
//Amount of meters in 1 AU
export const m_to_au = 1 / 149597870700;

export function rotate(cx, cy, x, y, angle) {
    var radians = (Math.PI / 180) * angle,
        cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = cos * (x - cx) + sin * (y - cy) + cx,
        ny = cos * (y - cy) - sin * (x - cx) + cy;
    return { x: nx, y: ny };
}

export const keplarDistance = (start, end) => {
    const result = distance(start.x, end.x, start.y, end.y, start.z, end.z);
    return result;
};

export const asteroidMinMax = {
    orbital_period: {
        min: 307.207,
        max: 2826.26,
        rounding: 1,
    },
    a: {
        min: 0.891,
        max: 3.912,
        rounding: 3,
    },
    e: {
        min: 0.007,
        max: 0.392,
        rounding: 3,
    },
    i: {
        min: 0,
        max: roundOff((0.659211 * 180) / Math.PI, 2),
        rounding: 2,
    },
    o: {
        min: 0,
        max: 360,
        rounding: 1,
    },
    m: {
        min: 0,
        max: 360,
        rounding: 1,
    },
    w: {
        min: 0,
        max: 360,
        rounding: 1,
    },
};

export function spectralType(id_spectral_type) {
    return influence.toSpectralType(id_spectral_type);
}

// Spectral type array
export const spectralTypes = influence.SPECTRAL_TYPES;

// Asteroid rarities
const raritiesNew = influence.RARITIES;
raritiesNew.unshift("Unscanned");
export const rarities = raritiesNew;

export function rarityColours(rarity) {
    if (rarity === "Incomparable") {
        return "#ffd94f";
    } else if (rarity === "Exceptional") {
        return "#ff984f";
    } else if (rarity === "Superior") {
        return "#884fff";
    } else if (rarity === "Rare") {
        return "#4f90ff";
    } else if (rarity === "Uncommon") {
        return "#69ebf4";
    } else if (rarity === "Common") {
        return "#bbbbbb";
    } else if (rarity === "Unscanned") {
        return "#333333";
    } else if (rarity === "Unowned") {
        return "#888888";
    } else {
        return null;
    }
}

export function typeColours(type) {
    const string = `${type}`;
    const lowerType = string.toLowerCase().slice(0, 1);

    if (lowerType === "c") {
        return "#5639CC";
    } else if (lowerType === "ci") {
        return "#4099FF";
    } else if (lowerType === "i") {
        return "#4099FF";
    } else if (lowerType === "m") {
        return "#AD3800";
    } else if (lowerType === "s") {
        return "#C48F00";
    } else {
        return null;
    }
}

export function materialColours(material) {
    if (material === "volatiles") {
        return "#536bd7";
    } else if (material === "metal") {
        return "#c97523";
    } else if (material === "organics") {
        return "#3e8f6e";
    } else if (material === "rare_earth") {
        return "#c24e35";
    } else if (material === "fissiles") {
        return "#bc58d5";
    }
}

export function asteroidSize(radius) {
    return radius <= 5000 ? "Small" : radius <= 20000 ? "Medium" : radius <= 50000 ? "Large" : "Huge";
}

//------------------------------------------------------------------------------------------------
// Sorter
//------------------------------------------------------------------------------------------------

// Sort object
export function sortObjectNew(sortOrder, sortArray, object, rev) {
    let array = [];

    // Get Array of keys
    sortArray.sort(function (a, b) {
        if (sortOrder === "Type") {
            return b.type - a.type || a.mer - b.mer || a.obf - b.obf;
        } else if (sortOrder === "MER") {
            return a.mer - b.mer || a.obf - b.obf;
        } else if (sortOrder === "Rarity") {
            let newA;
            let newB;
            influence.RARITIES.map((rarityName, index) => {
                if (a.rarity === rarityName) {
                    newA = index;
                }
                if (b.rarity === rarityName) {
                    newB = index;
                }
                return null;
            });
            return newA - newB || a.obf - b.obf || a.mer - b.mer;
        } else if (sortOrder === "ID") {
            return a.id - b.id;
        } else if (sortOrder === "OBF") {
            return a.obf - b.obf || a.mer - b.mer;
        } else if (sortOrder === "RarityMax") {
            let fa = a.type,
                fb = b.type;

            if (fa < fb) {
                return a.sort - b.sort || a.total - b.total || -1;
            }
            if (fa > fb) {
                return a.sort - b.sort || a.total - b.total || 1;
            }
            return a.sort - b.sort || a.total - b.total || 0;
        } else if (sortOrder === "RarityType") {
            let fa = a.type,
                fb = b.type;

            if (fa < fb) {
                return a.sort - b.sort || -1;
            }
            if (fa > fb) {
                return a.sort - b.sort || 1;
            }
            return a.sort - b.sort || 0;
        } else if (sortOrder === "Overall") {
            return a.overall - b.overall || a.career - b.career;
        } else if (sortOrder === "Class") {
            return rev ? a.id_class - b.id_class || a.career - b.career || a.looks - b.looks : a.id_class - b.id_class || b.career - a.career || b.looks - a.looks;
        } else if (sortOrder === "Career") {
            return a.career - b.career || a.looks - b.looks;
        } else if (sortOrder === "Traits") {
            return a.looks - b.looks || a.career - b.career;
        } else {
            return a - b;
        }
    });

    if (rev) {
        sortArray.reverse();
    }

    // Push new sorted array
    sortArray.forEach((key) => {
        array.push(object[key.id]);
    });

    return array;
}

// Sort string
export function sortString(array) {
    array.sort((a, b) => {
        let fa = a.type,
            fb = b.type;

        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });

    return array;
}

// Sort string
export function sortNumber(array) {
    array.sort((a, b) => {
        return a.total - b.total;
    });

    return array;
}

export function buildCareer(crewObject) {
    let careers = {};

    // Populate department names
    let departments = [];
    for (let key in jobs.department) {
        departments.push(key);
    }

    // For each department
    departments.map((departmentGroup) => {
        let departmentCrew = [];

        // For each tier
        for (let t = 0; t < 5; t++) {
            // For each owned crew member
            for (const crew in crewObject) {
                //console.log( crewObject[crew] );
                //console.log( titleName( crewObject[crew].id_title ));

                const collection_name = collectionName(crewObject[crew].id_collection);
                const class_name = className(crewObject[crew].id_class);
                const title = titleName(crewObject[crew].id_title);
                const title_department = titleDept(crewObject[crew].id_title);
                const title_tier = titleTier(crewObject[crew].id_title);

                if (departmentGroup === title_department && t === title_tier) {
                    departmentCrew.push({
                        id: parseInt(crew),
                        job: title,
                        class: class_name,
                        tier: title_tier,
                        collection: collection_name,
                    });
                }
            }
        }

        departmentCrew.sort(function (a, b) {
            return a.tier - b.tier || a.boost - b.boost || b.utility - a.utility;
        });

        return (careers[departmentGroup] = departmentCrew);
    });

    //console.log(careers);

    return careers;
}

// -----------------------------------------------------------------------------
// ORBIT DRAWING

export const makePoints = (originalPoints) => {
    let points = [];
    originalPoints.map((point, index) => {
        points.push([point.x, point.y * -1]);

        if (index === originalPoints.length - 1) {
            points.push([originalPoints[0].x, originalPoints[0].y * -1]);
        }
        return null;
    });
    return points;
};

export const makeIsoPoints = (originalPoints, size, ratio, angle) => {
    let points = [];
    let firstPoint = [];

    originalPoints.map((point, index) => {
        const newPoint = rotate(0, 0, point.x / size, point.y / size, angle);
        const angledPoint = { x: newPoint.x, y: newPoint.y * ratio, z: (point.z / size) * (1 - ratio) };
        if (index === 0) {
            firstPoint = angledPoint;
        }
        points.push([angledPoint.x, angledPoint.y + angledPoint.z]);

        if (index === originalPoints.length - 1) {
            points.push([firstPoint.x, firstPoint.y + firstPoint.z]);
        }
        return null;
    });
    return points;
};

export const makeIsoArcPoints = (originalPoints, size, ratio, angle) => {
    let points = [];

    originalPoints.map((point, index) => {
        const newPoint = rotate(0, 0, point.x / size, point.y / size, angle);
        const angledPoint = { x: newPoint.x, y: newPoint.y * ratio, z: (point.z / size) * (1 - ratio) };

        points.push([angledPoint.x, angledPoint.y + angledPoint.z]);

        return null;
    });
    return points;
};

export const makePath = (points, key, stroke = "#44CCCC33", fill = "none", opacity = 1, vectorEffect = "non-scaling-stroke", filter = "", strokeWidth = 1) => {
    // Render the svg <path> element
    // I:  - points (array): points coordinates
    //     - command (function)
    //       I:  - point (array) [x,y]: current point coordinates
    //           - i (integer): index of 'point' in the array 'a'
    //           - a (array): complete array of points coordinates
    //       O:  - (string) a svg path command
    // O:  - (string): a Svg <path> element
    const svgPath = (points, command, key) => {
        // build the d attributes by looping over the points
        const d = points.reduce(
            (acc, point, i, a) =>
                i === 0
                    ? // if first point
                      `M ${point[0]},${point[1]}`
                    : // else
                      `${acc} ${command(point, i, a)}`,
            ""
        );
        return <path d={d} key={key} fill={fill} stroke={stroke} strokeLinecap="round" strokeWidth={strokeWidth} vectorEffect={vectorEffect} opacity={opacity} filter={filter} />;
    };

    // Properties of a line
    // I:  - pointA (array) [x,y]: coordinates
    //     - pointB (array) [x,y]: coordinates
    // O:  - (object) { length: l, angle: a }: properties of the line
    const line = (pointA, pointB) => {
        const lengthX = pointB[0] - pointA[0];
        const lengthY = pointB[1] - pointA[1];
        return {
            length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
            angle: Math.atan2(lengthY, lengthX),
        };
    };

    // Position of a control point
    // I:  - current (array) [x, y]: current point coordinates
    //     - previous (array) [x, y]: previous point coordinates
    //     - next (array) [x, y]: next point coordinates
    //     - reverse (boolean, optional): sets the direction
    // O:  - (array) [x,y]: a tuple of coordinates
    const controlPoint = (current, previous, next, reverse) => {
        // When 'current' is the first or last point of the array
        // 'previous' or 'next' don't exist.
        // Replace with 'current'
        const p = previous || current;
        const n = next || current;

        // The smoothing ratio
        const smoothing = 0.188;

        // Properties of the opposed-line
        const o = line(p, n);

        // If is end-control-point, add PI to the angle to go backward
        const angle = o.angle + (reverse ? Math.PI : 0);
        const length = o.length * smoothing;

        // The control point position is relative to the current point
        const x = current[0] + Math.cos(angle) * length;
        const y = current[1] + Math.sin(angle) * length;

        return [x, y];
    };

    // Create the bezier curve command
    // I:  - point (array) [x,y]: current point coordinates
    //     - i (integer): index of 'point' in the array 'a'
    //     - a (array): complete array of points coordinates
    // O:  - (string) 'C x2,y2 x1,y1 x,y': SVG cubic bezier C command
    const bezierCommand = (point, i, a) => {
        const previous = a[i - 2] ? a[i - 2] : a[a.length - 2];
        const next = a[i + 1] ? a[i + 1] : a[1];

        // start control point
        const [cpsX, cpsY] = controlPoint(a[i - 1], previous, point);

        // end control point
        const [cpeX, cpeY] = controlPoint(point, a[i - 1], next, true);

        return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`;
    };

    return svgPath(points, bezierCommand, key);
};

export const makeArc = (points, key, stroke = "#44CCCC33", fill = "none", opacity = 1, vectorEffect = "non-scaling-stroke", filter = "", strokeWidth = 1) => {
    // Render the svg <path> element
    // I:  - points (array): points coordinates
    //     - command (function)
    //       I:  - point (array) [x,y]: current point coordinates
    //           - i (integer): index of 'point' in the array 'a'
    //           - a (array): complete array of points coordinates
    //       O:  - (string) a svg path command
    // O:  - (string): a Svg <path> element
    const svgPath = (points, command, key) => {
        // build the d attributes by looping over the points
        const d = points.reduce(
            (acc, point, i, a) =>
                i === 0
                    ? // if first point
                      `M ${point[0]},${point[1]}`
                    : // else
                      `${acc} ${command(point, i, a)}`,
            ""
        );
        return <path d={d} key={key} fill={fill} stroke={stroke} strokeLinecap="round" strokeWidth={strokeWidth} vectorEffect={vectorEffect} opacity={opacity} filter={filter} />;
    };

    // Properties of a line
    // I:  - pointA (array) [x,y]: coordinates
    //     - pointB (array) [x,y]: coordinates
    // O:  - (object) { length: l, angle: a }: properties of the line
    const line = (pointA, pointB) => {
        const lengthX = pointB[0] - pointA[0];
        const lengthY = pointB[1] - pointA[1];
        return {
            length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
            angle: Math.atan2(lengthY, lengthX),
        };
    };

    // Position of a control point
    // I:  - current (array) [x, y]: current point coordinates
    //     - previous (array) [x, y]: previous point coordinates
    //     - next (array) [x, y]: next point coordinates
    //     - reverse (boolean, optional): sets the direction
    // O:  - (array) [x,y]: a tuple of coordinates
    const controlPoint = (current, previous, next, reverse) => {
        // When 'current' is the first or last point of the array
        // 'previous' or 'next' don't exist.
        // Replace with 'current'
        const p = previous || current;
        const n = next || current;

        // The smoothing ratio
        const smoothing = 0.188;

        // Properties of the opposed-line
        const o = line(p, n);

        // If is end-control-point, add PI to the angle to go backward
        const angle = o.angle + (reverse ? Math.PI : 0);
        const length = o.length * smoothing;

        // The control point position is relative to the current point
        const x = current[0] + Math.cos(angle) * length;
        const y = current[1] + Math.sin(angle) * length;

        return [x, y];
    };

    // Create the bezier curve command
    // I:  - point (array) [x,y]: current point coordinates
    //     - i (integer): index of 'point' in the array 'a'
    //     - a (array): complete array of points coordinates
    // O:  - (string) 'C x2,y2 x1,y1 x,y': SVG cubic bezier C command
    const bezierCommand = (point, i, a) => {
        const previous = a[i - 2] ? a[i - 2] : 0;
        const next = a[i + 1] ? a[i + 1] : 0;

        // start control point
        const [cpsX, cpsY] = controlPoint(a[i - 1], previous, point);

        // end control point
        const [cpeX, cpeY] = controlPoint(point, a[i - 1], next, true);

        return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`;
    };

    return svgPath(points, bezierCommand, key);
};

export const makeOrbit = (points, refName, key, mask = "", fill = "#44CCCC33", stroke = "#44CCCC33", opacity = 1, width = 1, filter = "", vectorEffect = "non-scaling-stroke") => {
    // Render the svg <path> element
    const svgPath = (points, command, key) => {
        // build the d attributes by looping over the points
        const d = points.reduce(
            (acc, point, i, a) =>
                i === 0
                    ? // if first point
                      `M ${point[0]},${point[1]}`
                    : // else
                      `${acc} ${command(point, i, a)}`,
            ""
        );
        return <path ref={refName} d={d} key={key} fill={fill} stroke={stroke} strokeLinecap="round" strokeWidth={width} vectorEffect={vectorEffect} opacity={opacity} mask={mask} filter={filter} />;
    };

    // Properties of a line
    const line = (pointA, pointB) => {
        const lengthX = pointB[0] - pointA[0];
        const lengthY = pointB[1] - pointA[1];
        return {
            length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
            angle: Math.atan2(lengthY, lengthX),
        };
    };

    // Position of a control point
    const controlPoint = (current, previous, next, reverse) => {
        // When 'current' is the first or last point of the array
        // 'previous' or 'next' don't exist.
        // Replace with 'current'
        const p = previous || current;
        const n = next || current;

        // The smoothing ratio
        const smoothing = 0.188;

        // Properties of the opposed-line
        const o = line(p, n);

        // If is end-control-point, add PI to the angle to go backward
        const angle = o.angle + (reverse ? Math.PI : 0);
        const length = o.length * smoothing;

        // The control point position is relative to the current point
        const x = current[0] + Math.cos(angle) * length;
        const y = current[1] + Math.sin(angle) * length;

        return [x, y];
    };

    // Create the bezier curve command
    const bezierCommand = (point, i, a) => {
        const previous = a[i - 2] ? a[i - 2] : a[a.length - 2];
        const next = a[i + 1] ? a[i + 1] : a[1];

        // start control point
        const [cpsX, cpsY] = controlPoint(a[i - 1], previous, point);

        // end control point
        const [cpeX, cpeY] = controlPoint(point, a[i - 1], next, true);

        return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`;
    };

    return svgPath(points, bezierCommand, key);
};

export const hexagon = (x = 0, y = 0, radius = 6, long = 0, tall = 0) => {
    const hexHeight = Math.sqrt(3);
    let hexPath = `M${x - radius - long / 2},${y + tall / 2} `;
    hexPath += `l0,${-tall} l${radius / 2},${(-radius / 2) * hexHeight} l${radius + long},0 l${radius / 2},${(radius / 2) * hexHeight}`; //Upper half
    hexPath += `l0,${tall} l${-radius / 2},${(radius / 2) * hexHeight} l${-radius - long},0 `; //Lower half
    return (hexPath += "Z");
};

// -----------------------------------------------------------------------------
// PRODUCTION CHAINS
export function buildings() {
    return ["spaceport", "habitat", "marketplace", "warehouse", "extractor", "refinery", "factory", "shipyard"];
}

export function buildingColours(building) {
    const string = `${building}`.toLowerCase();

    if (string === "spaceport") {
        return "#5639CC";
    } else if (string === "habitat") {
        return "#666666";
    } else if (string === "marketplace") {
        return "#00ad38";
    } else if (string === "warehouse") {
        return "#4099FF";
    } else if (string === "extractor") {
        return "#3800ad";
    } else if (string === "refinery") {
        return "#C48F00";
    } else if (string === "factory") {
        return "#AD3800";
    } else if (string === "shipyard") {
        return "#ad001f";
    } else {
        return "none";
    }
}

export const resources = {
    1: { name: "Water", material: "volatiles", spectral: "ci" },
    2: { name: "Hydrogen", material: "volatiles", spectral: "i" },
    3: { name: "Ammonia", material: "volatiles", spectral: "i" },
    4: { name: "Nitrogen", material: "volatiles", spectral: "i" },
    5: { name: "Sulfur Dioxide", material: "volatiles", spectral: "i" },
    6: { name: "Carbon Dioxide", material: "volatiles", spectral: "ci" },
    7: { name: "Carbon Monoxide", material: "volatiles", spectral: "ci" },
    8: { name: "Methane", material: "volatiles", spectral: "ci" },
    9: { name: "Apatite", material: "organics", spectral: "c" },
    10: { name: "Bitumen", material: "organics", spectral: "c" },
    11: { name: "Calcite", material: "organics", spectral: "c" },
    12: { name: "Feldspar", material: "metal", spectral: "s" },
    13: { name: "Olivine", material: "metal", spectral: "s" },
    14: { name: "Pyroxene", material: "metal", spectral: "s" },
    15: { name: "Coffinite", material: "fissiles", spectral: "s" },
    16: { name: "Merrillite", material: "rare_earth", spectral: "s" },
    17: { name: "Xenotime", material: "rare_earth", spectral: "s" },
    18: { name: "Rhabdite", material: "metal", spectral: "m" },
    19: { name: "Graphite", material: "metal", spectral: "m" },
    20: { name: "Taenite", material: "metal", spectral: "m" },
    21: { name: "Troilite", material: "metal", spectral: "m" },
    22: { name: "Uraninite", material: "fissiles", spectral: "m" },
};

export function handleSaveToPC(jsonData, filename) {
    const fileData = JSON.stringify(jsonData, null, 4);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${filename}.json`;
    link.href = url;
    link.click();
}
