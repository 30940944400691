import React, { useEffect, useState, Suspense, lazy } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { HashRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import Header from "./components/sections/Header";
//import Hub from "./pages/Hub";

// import Crew from "./pages/Crew";
// import Assignments from "./pages/crew/Assignments";
// import Career from "./pages/crew/Career";
// import CrewRankings from "./pages/crew/CrewRankingsNEW";
// import MyCrew from "./pages/crew/MyCrew";
// import Rarity from "./pages/crew/Rarity";
// import SquadBuilder from "./pages/crew/SquadBuilder";

// import Asteroid from "./pages/Asteroid";
// import AsteroidRankings from "./pages/asteroid/AsteroidRankings";
// import MyPlots from "./pages/asteroid/MyPlots";
// import OverviewAdalia from "./pages/asteroid/OverviewAdalia";
// import OverviewPlots from "./pages/asteroid/OverviewPlots";
// import OverviewSpectral from "./pages/asteroid/OverviewSpectral";

// import Blockchain from "./pages/blockchain/Blockchain";

// import Associates from "./pages/Associates";

const Hub = lazy(() => import("./pages/Hub"));

const Crew = lazy(() => import("./pages/Crew"));
const Assignments = lazy(() => import("./pages/crew/Assignments"));
const Career = lazy(() => import("./pages/crew/Career"));
const CrewRankings = lazy(() => import("./pages/crew/CrewRankingsNEW"));
const MyCrew = lazy(() => import("./pages/crew/MyCrew"));
const Rarity = lazy(() => import("./pages/crew/Rarity"));
const Squads = lazy(() => import("./pages/crew/Squads"));
const CrewDatasheet = lazy(() => import("./pages/crew/CrewDatasheet"));

const Asteroid = lazy(() => import("./pages/Asteroid"));
const AsteroidRankings = lazy(() => import("./pages/asteroid/AsteroidRankings"));
const MyPlots = lazy(() => import("./pages/asteroid/MyPlots"));
const OverviewAdalia = lazy(() => import("./pages/asteroid/OverviewAdalia"));
const OverviewPlots = lazy(() => import("./pages/asteroid/OverviewPlots"));
const OverviewSpectral = lazy(() => import("./pages/asteroid/OverviewSpectral"));
const OverviewOrbital = lazy(() => import("./pages/asteroid/KeplerianOrbits"));
const AsteroidDatasheet = lazy(() => import("./pages/asteroid/AsteroidDatasheet"));
const SystemMap = lazy(() => import("./pages/asteroid/SystemMap"));

const ProductionChain = lazy(() => import("./pages/production/ProductionChain"));
const ProductionChainNetwork = lazy(() => import("./pages/production/ProductionChainNetwork"));

const Blockchain = lazy(() => import("./pages/blockchain/Blockchain"));
const OverviewBlockchain = lazy(() => import("./pages/blockchain/OverviewBlockevent"));
const Associates = lazy(() => import("./pages/Associates"));
const Widgets = lazy(() => import("./pages/widgets/WidgetHome"));

const Coastin = lazy(() => import("./pages/widgets/Coastin"));
const PlotLayout = lazy(() => import("./pages/widgets/PlotLayout"));

// Use-query-params
// https://www.robinwieruch.de/react-router-search-params/
// https://github.com/pbeshai/use-query-params/blob/master/examples/react-router-6/src/index.js
const RouteAdapter = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const adaptedHistory = React.useMemo(
        () => ({
            replace(location) {
                navigate(location, { replace: true, state: location.state });
            },
            push(location) {
                navigate(location, { replace: false, state: location.state });
            },
        }),
        [navigate]
    );

    return children({ history: adaptedHistory, location });
};

export default function App() {
    const [accountState, setAccountState] = useState({
        connected: false,
        account: null,
        chainId: null,
        balance: null,
        crewBalance: null,
        crewSupply: null,
        roidBalance: null,
        roidSupply: null,
    });

    const [accountExtras, setAccountExtras] = useState({
        complete: false,
        ensName: null,
        ensPfp: null,
    });

    const [pageStatus, setPageStatus] = useState("Connect wallet to access Influence Assets");

    // Hide loading screen
    useEffect(() => {
        const ele = document.getElementById("loading-screen");
        ele.outerHTML = "";
    }, []);

    return (
        <Router>
            <QueryParamProvider ReactRouterRoute={RouteAdapter}>
                <Suspense
                    fallback={
                        <>
                            <Header accountState={accountState} setAccountState={setAccountState} accountExtras={accountExtras} setAccountExtras={setAccountExtras} />
                            <Flex direction="column" align="center" maxW={{ xl: "1400px" }} m="0 auto">
                                <Text color="whiteAlpha.600" fontSize="32px">
                                    Loading...
                                </Text>
                            </Flex>
                        </>
                    }
                >
                    <Routes>
                        <Route path="/widgets/coastin" element={<Coastin />}></Route>
                        <Route path="/widgets/plotLayout" element={<PlotLayout />}></Route>
                        <Route path="/" element={<Header accountState={accountState} setAccountState={setAccountState} accountExtras={accountExtras} setAccountExtras={setAccountExtras} />}>
                            <Route index element={<Hub />}></Route>

                            <Route path="/crew" element={<Crew />}></Route>
                            <Route path="/crew/mycrew" element={<MyCrew state={accountState} pageStatus={pageStatus} setPageStatus={setPageStatus} />}></Route>
                            <Route path="/crew/careers" element={<Career state={accountState} />}></Route>
                            <Route path="/crew/rarity" element={<Rarity />}></Route>
                            <Route path="/crew/squad" element={<Squads state={accountState} pageStatus={pageStatus} setPageStatus={setPageStatus} />}></Route>
                            <Route path="/crew/assignments" element={<Assignments />}></Route>
                            <Route path="/crew/rankings" element={<CrewRankings state={accountState} />}></Route>
                            <Route path="/crew/:id" element={<CrewDatasheet />}></Route>

                            <Route path="/asteroid" element={<Asteroid />}></Route>
                            <Route path="/asteroid/myplots" element={<MyPlots state={accountState} pageStatus={pageStatus} setPageStatus={setPageStatus} />}></Route>
                            <Route path="/asteroid/rankings" element={<AsteroidRankings state={accountState} />}></Route>
                            <Route path="/asteroid/overview" element={<OverviewPlots state={accountState} pageStatus={pageStatus} />}></Route>
                            <Route path="/asteroid/:id" element={<AsteroidDatasheet />}></Route>

                            {/* <Route path="/production/productionChain" element={<ProductionChain />}></Route> */}
                            <Route path="/production/productionChainNetwork" element={<ProductionChainNetwork />}></Route>

                            <Route path="/overview/adalia" element={<OverviewAdalia />}></Route>
                            <Route path="/overview/spectral" element={<OverviewSpectral />}></Route>
                            <Route path="/overview/keplerianOrbits" element={<OverviewOrbital />}></Route>
                            <Route path="/adalia" element={<SystemMap />}></Route>

                            <Route path="/blockchain" element={<Blockchain state={accountState} />}></Route>
                            <Route path="/blockgraph" element={<OverviewBlockchain />}></Route>

                            <Route path="/associates" element={<Associates />}></Route>
                            <Route path="/widgets" element={<Widgets />}></Route>
                        </Route>
                    </Routes>
                </Suspense>
            </QueryParamProvider>
        </Router>
    );
}
