import { Web3ReactProvider } from '@web3-react/core'

import { hooks as metaMaskHooks, metaMask } from './connectors/metaMask'
import { hooks as walletConnectHooks, walletConnect } from './connectors/walletConnect'
import { hooks as coinbaseWalletHooks, coinbaseWallet } from './connectors/coinbaseWallet'
import { hooks as networkHooks, network } from './connectors/network'

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
  [coinbaseWallet, coinbaseWalletHooks],
  [network, networkHooks],
];

export default function Web3Provider() {
  return (
    <Web3ReactProvider connectors={connectors}>

    </Web3ReactProvider>
  )
}