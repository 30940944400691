import React from "react"
import { Box } from "@chakra-ui/react"
 
export default function LogoMM(props) {
  return (
    <Box {...props}>
        <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.9718 4.01129L19.7288 12.3616L21.8079 7.43502L30.9718 4.01129Z" fill="#E2761B" stroke="#E2761B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.01697 4.01129L16.1695 12.4407L14.1921 7.43502L5.01697 4.01129Z" fill="#E4761B" stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.9265 23.3672L23.9322 27.9548L30.3389 29.7176L32.1807 23.4689L26.9265 23.3672Z" fill="#E4761B" stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.83051 23.4689L5.66101 29.7176L12.0678 27.9548L9.07344 23.3672L3.83051 23.4689Z" fill="#E4761B" stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.7062 15.6158L9.9209 18.3164L16.2825 18.5989L16.0565 11.7627L11.7062 15.6158Z" fill="#E4761B" stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.2825 15.6158L19.8757 11.6836L19.7288 18.5988L26.0791 18.3163L24.2825 15.6158Z" fill="#E4761B" stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0678 27.9548L15.887 26.0904L12.5876 23.5142L12.0678 27.9548Z" fill="#E4761B" stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.1017 26.0904L23.9322 27.9548L23.4011 23.5142L20.1017 26.0904Z" fill="#E4761B" stroke="#E4761B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.9322 27.9548L20.1017 26.0904L20.4068 28.5875L20.3729 29.6384L23.9322 27.9548Z" fill="#D7C1B3" stroke="#D7C1B3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0678 27.9548L15.6271 29.6384L15.6045 28.5875L15.887 26.0904L12.0678 27.9548Z" fill="#D7C1B3" stroke="#D7C1B3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.6836 21.8644L12.4972 20.9266L14.7458 19.8983L15.6836 21.8644Z" fill="#233447" stroke="#233447" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.3051 21.8644L21.243 19.8983L23.5029 20.9266L20.3051 21.8644Z" fill="#233447" stroke="#233447" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0678 27.9548L12.6102 23.3672L9.07343 23.4689L12.0678 27.9548Z" fill="#CD6116" stroke="#CD6116" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.3898 23.3672L23.9322 27.9548L26.9266 23.4689L23.3898 23.3672Z" fill="#CD6116" stroke="#CD6116" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.0791 18.3164L19.7288 18.5989L20.3164 21.8645L21.2542 19.8984L23.5141 20.9266L26.0791 18.3164Z" fill="#CD6116" stroke="#CD6116" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.4972 20.6102L14.7571 19.5819L15.6836 21.548L16.2825 18.2825L9.9209 18L12.4972 20.6102Z" fill="#CD6116" stroke="#CD6116" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.9209 18.3164L12.5876 23.5142L12.4972 20.9266L9.9209 18.3164Z" fill="#E4751F" stroke="#E4751F" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.5141 20.9266L23.4011 23.5142L26.0791 18.3164L23.5141 20.9266Z" fill="#E4751F" stroke="#E4751F" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.2825 18.5989L15.6836 21.8645L16.4294 25.7176L16.5988 20.6441L16.2825 18.5989Z" fill="#E4751F" stroke="#E4751F" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.7288 18.5989L19.4237 20.6328L19.5593 25.7176L20.3164 21.8645L19.7288 18.5989Z" fill="#E4751F" stroke="#E4751F" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.3164 21.8644L19.5593 25.7175L20.1017 26.0904L23.4011 23.5141L23.5141 20.9265L20.3164 21.8644Z" fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.4972 20.9265L12.5876 23.5141L15.887 26.0904L16.4294 25.7175L15.6836 21.8644L12.4972 20.9265Z" fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.3729 29.6384L20.4068 28.5875L20.1243 28.339H15.8644L15.6045 28.5875L15.6271 29.6384L12.0678 27.9548L13.3107 28.9717L15.8305 30.7231H20.1582L22.6893 28.9717L23.9322 27.9548L20.3729 29.6384Z" fill="#C0AD9E" stroke="#C0AD9E" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.1017 26.0904L19.5593 25.7176H16.4293L15.887 26.0904L15.6045 28.5876L15.8644 28.339H20.1243L20.4068 28.5876L20.1017 26.0904Z" fill="#161616" stroke="#161616" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M31.4463 12.9039L32.4068 8.29378L30.9717 4.01129L20.1017 12.0791L24.2825 15.6158L30.1921 17.3446L31.5028 15.8192L30.9378 15.4124L31.8418 14.5876L31.1412 14.0452L32.0452 13.3559L31.4463 12.9039Z" fill="#763D16" stroke="#763D16" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.5932 8.29378L4.55365 12.9039L3.94348 13.3559L4.84744 14.0452L4.15817 14.5876L5.06213 15.4124L4.49716 15.8192L5.79659 17.3446L11.7062 15.6158L15.887 12.0791L5.01693 4.01129L3.5932 8.29378Z" fill="#763D16" stroke="#763D16" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M30.1921 17.3446L24.2825 15.6158L26.0791 18.3164L23.4011 23.5141L26.9265 23.4689H32.1808L30.1921 17.3446Z" fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.7062 15.6158L5.79661 17.3446L3.83051 23.4689H9.07344L12.5876 23.5141L9.9209 18.3164L11.7062 15.6158Z" fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.7288 18.5989L20.1017 12.0791L21.8192 7.43506H14.1921L15.887 12.0791L16.2825 18.5989L16.4181 20.6554L16.4294 25.7175H19.5593L19.5819 20.6554L19.7288 18.5989Z" fill="#F6851B" stroke="#F6851B" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </Box>
  )
}