import React from "react"
import { Box } from "@chakra-ui/react"
 
export default function LogoCB(props) {
  return (
    <Box {...props}>
        <svg viewBox="0 0 270.78 270.77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m129.72 270.67c-35.705-2.1491-65.672-15.357-89.969-39.654-36.388-36.388-49.009-89.641-32.799-138.39 16.225-48.791 59.051-84.388 109.94-91.38 12.096-1.662 24.946-1.662 37.042 0 59.899 8.2302 107.36 55.69 115.59 115.59 2.8294 20.592 0.8937 41.562-5.6537 61.251-16.014 48.154-57.919 83.48-108.09 91.122-7.3963 1.1264-19.924 1.8284-26.051 1.4596z" fill="#0052ff" strokeWidth=".26458"/>
            <path d="m148.64 229.86c42.929-6.4994 75.193-38.776 81.24-81.272 0.27953-1.9645 0.51012-3.9595 0.51239-4.4332l4e-3 -0.86128-48.23 0.135-0.60026 2.6458c-4.0827 17.996-18.434 31.905-37.027 35.889-4.2591 0.91244-13.627 0.91845-18.104 0.0116-22.462-4.5488-38.375-23.878-38.375-46.616s15.912-42.067 38.374-46.616c4.417-0.89449 13.832-0.89998 18.042-0.01053 19.45 4.1083 33.334 18.119 37.618 37.961l0.15711 0.72761h48.144v-0.74443c0-1.2018-0.74729-6.5694-1.3329-9.5743-7.3943-37.939-36.768-67.524-74.735-75.272-7.4629-1.523-19.494-2.1742-27.346-1.48-40.329 3.5658-74.208 32.444-84.023 71.62-2.1005 8.3842-2.6669 13.351-2.6669 23.388 0 10.037 0.56636 15.004 2.6669 23.388 9.7231 38.81 43 67.48 83.079 71.578 4.3268 0.44241 18.54 0.15023 22.601-0.46464z" fill="#fff" strokeWidth=".26458"/>
        </svg>
    </Box>
  )
}