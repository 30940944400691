import { useMemo, useEffect, useState, useCallback } from "react";
import { Box, Image, Button, Flex, Spacer, Divider, Center, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Text, useDisclosure } from "@chakra-ui/react";
import { ExternalLinkIcon, ViewIcon, ViewOffIcon, LinkIcon, CopyIcon, NotAllowedIcon } from "@chakra-ui/icons";
import { BrowserView, MobileView } from "react-device-detect";

import { ethers } from "ethers";
import { formatEther } from "@ethersproject/units";

import { hooks as metaMaskHooks, metaMask } from "../../services/connectors/metaMask";
import { hooks as walletConnectHooks, walletConnect } from "../../services/connectors/walletConnect";
import { hooks as coinbaseWalletHooks, coinbaseWallet } from "../../services/connectors/coinbaseWallet";
//import { hooks as networkHooks, network } from '../../services/connectors/network';
import { MetaMask } from "@web3-react/metamask";
import { WalletConnect } from "@web3-react/walletconnect";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { Network } from "@web3-react/network";
import { CHAINS, getAddChainParameters } from "../../services/chains";

import Identicon from "./Identicon";

import LogoMM from "../ui/Logo_metamask";
import LogoWC from "../ui/Logo_walletConnect";
import LogoCB from "../ui/Logo_coinbase";

// Crew Contract Information
import crewContractAbi from "../../contracts/crewContractABI.json";
import { crewContractAddress } from "../../contracts/crewContractAddress";

// Asteroid Contract Information
import asteroidContractAbi from "../../contracts/asteroidContractABI.json";
import { asteroidContractAddress } from "../../contracts/asteroidContractAddress";

import { copyToClipboard } from "../../hooks/hooks";

// function ChainSelect({
//     chainId,
//     switchChain,
//     displayDefault,
//     chainIds,
// }) {
//     return (
//         <select
//             value={chainId}
//             onChange={ (event) => { switchChain?.(Number(event.target.value)) } }
//             disabled={switchChain === undefined}
//         >
//             {displayDefault ? <option value={-1}>Default Chain</option> : null}

//             {chainIds.map((chainId) => (
//                 <option key={chainId} value={chainId}>
//                     {CHAINS[chainId]?.name ?? chainId}
//                 </option>
//             ))}

//         </select>
//     )
// }

function getName(connector) {
    if (connector instanceof MetaMask) return "MetaMask";
    if (connector instanceof WalletConnect) return "WalletConnect";
    if (connector instanceof CoinbaseWallet) return "Coinbase Wallet";
    if (connector instanceof Network) return "Network";
    return "Unknown";
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default function ConnectButton({ accountState, setAccountState, accountExtras, setAccountExtras }) {
    // For modal
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [detailsHidden, setDetailsHidden] = useState(false);

    // States
    const [connected, setConnected] = useState(accountState.connected);
    const [extrasLoaded, setExtrasLoaded] = useState(accountExtras.complete);
    const [walletAccount, setWalletAccount] = useState(accountState.walletAccount);
    const [ensName, setEnsName] = useState(accountExtras.ensName);
    const [ensPfp, setEnsPfp] = useState(accountExtras.ensPfp);

    const [myConnector, setMyConnector] = useState(null);
    const [walletChainId, setWalletChainId] = useState(accountState.chainId);
    const [addressLink, setAddressLink] = useState();

    const [ethBalance, setEthBalance] = useState(accountState.ethBalance);
    const [supplyCrew, setSupplyCrew] = useState(accountState.crewSupply);

    const [ownedCrew, setOwnedCrew] = useState(accountState.crewBalance);
    const [ownedRoid, setOwnedRoid] = useState(accountState.roidBalance);

    const supplyRoid = 11127;

    let nullState = useMemo(() => {
        return {
            connected: false,
            account: null,
            chainId: null,
            balance: null,
            crewBalance: null,
            crewSupply: null,
            roidBalance: null,
            roidSupply: null,
        };
    }, []);

    let nullExtras = useMemo(() => {
        return {
            complete: false,
            ensName: null,
            ensPfp: null,
        };
    }, []);

    const closeConnection = (connection) => {
        if (connection === metaMask) {
            //console.log( "MM Reset")
            metaMask.deactivate();
        } else if (connection === walletConnect) {
            //console.log( "WC Reset")
            walletConnect.deactivate();
            walletConnect.walletConnectProvider = undefined;
        } else if (connection === coinbaseWallet) {
            //console.log( "WC Reset")
            coinbaseWallet.deactivate();
        }
        setMyConnector(null);
        setWalletAccount();
        setEnsName(null);
        setEnsPfp(null);
        setWalletChainId();
        setAddressLink();
        setEthBalance();
        setSupplyCrew();
        setOwnedCrew(null);
        setOwnedRoid(null);
        setAccountState(nullState);
        setAccountExtras(nullExtras);
        setConnected(false);
        setExtrasLoaded(false);

        onClose();
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // -------------------------------------------------------------------
    // UPDATED -> Web3 / walletconnect Functions
    // -------------------------------------------------------------------
    const walletHooks = myConnector === metaMask ? metaMaskHooks : myConnector === walletConnect ? walletConnectHooks : myConnector === coinbaseWallet ? coinbaseWalletHooks : metaMaskHooks;
    const { useChainId, useAccounts, useIsActivating, useIsActive, useProvider } = walletHooks; //useENSNames

    const chainId = useChainId();
    const accounts = useAccounts();
    //const error = useError();
    const isActivating = useIsActivating();
    const isActive = useIsActive();
    const provider = useProvider();

    // const isNetwork = myConnector instanceof Network;
    // const isWalletConnect = myConnector instanceof WalletConnect;
    // const displayDefault = !isNetwork;
    // const chainIds = (isNetwork ? Object.keys(URLS) : Object.keys(CHAINS)).map((chainId) => Number(chainId));
    // const chainName = chainId ? CHAINS[chainId]?.name : undefined;

    const [desiredChainId, setDesiredChainId] = useState(1);

    // eslint-disable-next-line
    const switchChain = useCallback(
        async (desiredChainId) => {
            setDesiredChainId(desiredChainId);

            // if we're already connected to the desired chain, return
            if (desiredChainId === chainId) return;

            // if they want to connect to the default chain and we're already connected, return
            if (desiredChainId === -1 && chainId !== undefined) return;

            if (myConnector instanceof WalletConnect || myConnector instanceof Network) {
                await myConnector.activate(desiredChainId === -1 ? undefined : desiredChainId);
            } else {
                await myConnector.activate(desiredChainId === -1 ? undefined : getAddChainParameters(desiredChainId));
            }
        },
        [myConnector, chainId]
    );

    //Try to connect on initial render
    useEffect(() => {
        if (myConnector === metaMask) {
            void metaMask.connectEagerly();
            //console.log("MM Eager connect!", myConnector)
        } else if (myConnector === walletConnect) {
            void walletConnect.connectEagerly();
            //console.log("WC Eager connect!", myConnector)
        }
    }, [myConnector]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // -------------------------------------------------------------------
    // Blockchain calls
    // -------------------------------------------------------------------

    // account = '0xef0133437ab8da5c5e8873b61189610e2d8cb4f5';
    // account = '0xC2aD5F91f2c67E702dfe65C9689E4205774927Ce';
    useEffect(() => {
        if (!connected && accounts && chainId && accounts[0] !== walletAccount) {
            setWalletAccount(accounts[0]);
        }
    }, [connected, accounts, walletAccount, chainId]);

    //fetch eth balance of the connected account
    useEffect(() => {
        if (!ethBalance && provider && walletAccount) {
            provider
                .getBalance(walletAccount)
                .then((balance) => {
                    setEthBalance(balance);
                })
                .catch(() => {
                    setEthBalance(null);
                });
        }
    }, [ethBalance, provider, walletAccount]);

    // -------------------------------------------------------------------
    // Get Chain name
    // -------------------------------------------------------------------
    useEffect(() => {
        if (accounts && chainId) {
            //console.log( "Set address: ", accounts, chainId)
            setWalletChainId(chainId);
            setAddressLink(CHAINS[chainId]?.blockExplorerUrls + "address/" + accounts[0] ?? null);
        }
    }, [accounts, chainId]);

    // -------------------------------------------------------------------
    // Get crew supply
    // -------------------------------------------------------------------
    useEffect(() => {
        const getSupplyCrew = async () => {
            if (isActive && provider && chainId === 1) {
                const crewContract = new ethers.Contract(crewContractAddress, crewContractAbi, provider);
                const crewSupply = await crewContract.totalSupply();
                setSupplyCrew(parseInt(crewSupply, 10));
                //console.log( "Get Supply: ", isActive, provider, crewSupply)
            }
        };
        !supplyCrew && getSupplyCrew();
    }, [supplyCrew, isActive, provider, chainId]);

    // -------------------------------------------------------------------
    // Get owned assets
    // -------------------------------------------------------------------
    useEffect(() => {
        const getOwnedCrew = async () => {
            if (!ownedCrew && isActive && walletAccount && chainId === 1) {
                const crewContract = new ethers.Contract(crewContractAddress, crewContractAbi, provider);
                const crewOwned = await crewContract.balanceOf(walletAccount);
                //console.log( "Get Owned Crew: ", parseInt(crewOwned, 10), isActive, walletAccount)
                setOwnedCrew(parseInt(crewOwned, 10));
            }
        };

        !ownedCrew && getOwnedCrew();
    }, [ownedCrew, isActive, walletAccount, provider, chainId]);

    useEffect(() => {
        const getOwnedRoid = async () => {
            if (!ownedRoid && isActive && walletAccount && chainId === 1) {
                const asteroidContract = new ethers.Contract(asteroidContractAddress, asteroidContractAbi, provider);
                const roidOwned = await asteroidContract.balanceOf(walletAccount);
                //console.log( "Get Owned Roid: ", parseInt(roidOwned, 10), isActive, walletAccount)
                setOwnedRoid(parseInt(roidOwned, 10));
            }
        };

        !ownedRoid && getOwnedRoid();
    }, [ownedRoid, isActive, walletAccount, provider, chainId]);

    useEffect(() => {
        const getEns = async () => {
            if (!extrasLoaded && isActive && provider && walletAccount) {
                const lookupName = await provider.lookupAddress(walletAccount).catch((err) => {});
                if (lookupName) {
                    setEnsName(lookupName);
                    const resolver = await provider.getResolver(lookupName);
                    const pfpUrl = await resolver.getAvatar();
                    setEnsPfp(pfpUrl ? pfpUrl.url : null);
                } else {
                    setEnsName("");
                }
                setExtrasLoaded(true);
                setAccountExtras({
                    complete: true,
                    ensName: ensName,
                    ensPfp: ensPfp,
                });
            }
        };

        getEns();
    }, [ensName, ensPfp, isActive, provider, walletAccount, extrasLoaded, setAccountExtras]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // -------------------------------------------------------------------
    // Set master accountState
    // -------------------------------------------------------------------
    useEffect(() => {
        if (!connected && isActive && walletAccount && ethBalance && walletChainId && ownedRoid !== null && ownedCrew !== null && supplyRoid && supplyCrew) {
            setAccountState({
                connected: true,
                account: walletAccount,
                chainId: walletChainId,
                balance: ethBalance,
                crewBalance: ownedCrew,
                crewSupply: supplyCrew,
                roidBalance: ownedRoid,
                roidSupply: supplyRoid,
            });
            setConnected(true);
        }
    }, [connected, setAccountState, isActive, walletAccount, walletChainId, ethBalance, ownedCrew, supplyCrew, ownedRoid, supplyRoid]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return connected ? (
        <Box mt={{ base: 2, md: 0 }} mb={-4} display="column" alignItems="center">
            <Flex>
                <Box display="flex" alignItems="center" background="primary.300" borderRadius="10px" py="0">
                    <Box pt="2px" mx="4px" minWidth="40px">
                        <Text textAlign="right" color="primary.900" fontSize="12px">
                            {detailsHidden || ethBalance === undefined ? "... Ξ" : ethBalance === null ? "Err Ξ" : `${parseFloat(formatEther(ethBalance)).toPrecision(2)} Ξ`}
                        </Text>
                    </Box>

                    <Button
                        onClick={onOpen}
                        bg="primary.600"
                        border="3px solid transparent"
                        _hover={{
                            border: "1px",
                            borderStyle: "solid",
                            borderColor: "blue.400",
                            backgroundColor: "gray.700",
                        }}
                        borderRadius="9px"
                        m={0.5}
                        px={3}
                        height="30px"
                    >
                        <Text color={detailsHidden ? "whiteAlpha.600" : "white"} fontSize="13px" fontWeight="medium" mr="2">
                            {walletAccount === undefined ? "..." : walletAccount === null ? "None" : detailsHidden ? "[ REDACTED ]" : ensName && ensName.length > 12 ? `${ensName.substring(0, Math.min(12, ensName.length - 4))}... ${ensName.substring(ensName.length - 4)}` : ensName ? `${ensName}` : `${walletAccount.substring(0, 6)}...${walletAccount.substring(walletAccount.length - 4)}`}
                        </Text>
                        {detailsHidden ? (
                            <NotAllowedIcon w="22px" h="22px" color="blackAlpha.300" />
                        ) : ensPfp ? (
                            <Box overflow="hidden">
                                <Image w="22px" h="22px" rounded="11px" src={ensPfp} alt={"ENS Avatar"} />
                            </Box>
                        ) : (
                            <Box pt="4px">
                                <Identicon account={walletAccount} />
                            </Box>
                        )}
                    </Button>

                    <Box pr="2" pl="1" minWidth="40px">
                        <Text textAlign="right" color="primary.900" fontSize="12px" lineHeight="1">
                            {detailsHidden || ownedRoid === undefined ? "..." : ownedRoid === null ? "Err" : ownedRoid} <small> roids</small>
                        </Text>
                        <Text textAlign="right" color="primary.900" fontSize="12px" lineHeight="1">
                            {detailsHidden || ownedCrew === undefined ? "..." : ownedCrew === null ? "Err" : ownedCrew} <small> crew</small>
                        </Text>
                    </Box>
                </Box>
            </Flex>

            <Text textAlign="center" color="primary.900" fontSize="sm" fontWeight="medium">
                {CHAINS[walletChainId]?.name ?? walletChainId}
            </Text>

            {/*---------------------Modal------------------------------*/}

            <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
                <ModalOverlay />
                <ModalContent background="gray.900" border="1px" borderStyle="solid" borderColor="gray.700" borderRadius="3xl">
                    <ModalHeader color="whiteAlpha.800" px={9} py={2} fontSize="22px" fontWeight="normal">
                        <Flex>
                            Account Management
                            <Center w="36px" h="34px">
                                <Button
                                    size="xs"
                                    variant="ghost"
                                    onClick={() => {
                                        setDetailsHidden(!detailsHidden);
                                    }}
                                >
                                    {detailsHidden ? <ViewIcon w="16px" h="16px" opacity={0.3} /> : <ViewOffIcon w="16px" h="16px" opacity={0.1} />}
                                </Button>
                            </Center>
                        </Flex>
                    </ModalHeader>

                    <ModalCloseButton color="white" fontSize="sm" _hover={{ color: "whiteAlpha.700" }} />
                    <ModalBody pt={0} px={4}>
                        <Box borderRadius="3xl" border="1px" borderStyle="solid" borderColor="gray.600" px={5} pt={3} pb={2} mb={3}>
                            <Flex justifyContent="space-between" alignItems="center" mb={3}>
                                <Text color="gray.400" fontSize="sm">
                                    Connected with {getName(myConnector)}
                                </Text>

                                <Button
                                    variant="outline"
                                    size="sm"
                                    borderColor="blue.800"
                                    borderRadius="3xl"
                                    color="blue.500"
                                    fontSize="13px"
                                    fontWeight="normal"
                                    px={2}
                                    height="26px"
                                    _hover={{
                                        background: "none",
                                        borderColor: "blue.300",
                                        textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                        closeConnection(myConnector);
                                    }}
                                >
                                    Change
                                </Button>
                            </Flex>
                            <Divider />
                            <Flex alignItems="center" mt={4} mb={4} lineHeight={1}>
                                {detailsHidden ? (
                                    ensPfp ? (
                                        <NotAllowedIcon w="50px" h="50px" color="whiteAlpha.300" />
                                    ) : (
                                        <NotAllowedIcon w="20px" h="20px" color="whiteAlpha.300" />
                                    )
                                ) : ensPfp ? (
                                    <Box overflow="hidden">
                                        <Image w="50px" h="50px" rounded="3px" src={ensPfp} alt={"ENS Avatar"} />
                                    </Box>
                                ) : (
                                    <Box w="20px" pt="4px">
                                        <Identicon account={walletAccount} />
                                    </Box>
                                )}
                                <Flex w="160px" direction="column">
                                    <Text color="white" fontSize="xl" fontWeight="semibold" ml="2" lineHeight="1.4">
                                        {ensName ? (detailsHidden ? "[ REDACTED ]" : ensName) : null}
                                    </Text>
                                    <Text color={ensName ? "whiteAlpha.500" : "white"} fontSize="lg" fontWeight="semibold" ml="2" lineHeight="1.4">
                                        {walletAccount === undefined ? "..." : walletAccount === null ? "None" : detailsHidden ? "[ REDACTED ]" : `${walletAccount.substring(0, 6)}...${walletAccount.substring(walletAccount.length - 4)}`}
                                    </Text>
                                </Flex>
                                <Spacer />
                                <Flex w="50px" direction="column">
                                    <Text color="whiteAlpha.800" textAlign="center" fontSize="2xl" fontWeight="normal" lineHeight="1.1">
                                        {detailsHidden ? "..." : ownedRoid}
                                    </Text>
                                    <Text color="whiteAlpha.300" textAlign="center" fontSize="lg" fontWeight="normal" lineHeight="1.1">
                                        INFA
                                    </Text>
                                </Flex>
                                <Flex w="50px" direction="column">
                                    <Text color="whiteAlpha.800" textAlign="center" fontSize="2xl" fontWeight="normal" lineHeight="1.1">
                                        {detailsHidden ? "..." : ownedCrew}
                                    </Text>
                                    <Text color="whiteAlpha.300" textAlign="center" fontSize="lg" fontWeight="normal" lineHeight="1.1">
                                        INFC
                                    </Text>
                                </Flex>
                            </Flex>
                            <Divider />
                            <Flex alignContent="center" mt={2} mx={3}>
                                <Button
                                    variant="link"
                                    color="gray.400"
                                    fontWeight="normal"
                                    fontSize="sm"
                                    _hover={{
                                        textDecoration: "none",
                                        color: "whiteAlpha.800",
                                    }}
                                    onClick={() => copyToClipboard(ensName)}
                                    isDisabled={!ensName}
                                >
                                    <CopyIcon mr={1} />
                                    Copy ENS
                                </Button>
                                <Spacer />
                                <Button
                                    variant="link"
                                    color="gray.400"
                                    fontWeight="normal"
                                    fontSize="sm"
                                    _hover={{
                                        textDecoration: "none",
                                        color: "whiteAlpha.800",
                                    }}
                                    onClick={() => copyToClipboard(walletAccount)}
                                >
                                    <CopyIcon mr={1} />
                                    Copy Address
                                </Button>
                                <Spacer />
                                <Link
                                    fontSize="sm"
                                    display="flex"
                                    alignItems="center"
                                    href={addressLink}
                                    isExternal
                                    color="gray.400"
                                    _hover={{
                                        color: "whiteAlpha.800",
                                        textDecoration: "underline",
                                    }}
                                >
                                    <ExternalLinkIcon mr={1} />
                                    View on Explorer
                                </Link>
                            </Flex>
                        </Box>
                    </ModalBody>

                    <ModalFooter background="gray.700" borderBottomLeftRadius="3xl" borderBottomRightRadius="3xl" py={0}>
                        <Button
                            p={0}
                            variant="ghost"
                            color="gray.400"
                            fontWeight="normal"
                            fontSize="sm"
                            _hover={{
                                textDecoration: "none",
                                color: "whiteAlpha.800",
                            }}
                            onClick={() => copyToClipboard(walletAccount)}
                            isDisabled
                        >
                            Manage Connected Accounts
                            <LinkIcon ml={1} />
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    ) : (
        <Flex mt={{ base: 0, md: 0 }} mb={-2} display="row" justify="center" alignItems="center">
            <BrowserView>
                <Flex direction={{ base: "row", md: "row" }} justify={{ base: "space-around", md: "space-around" }} alignItems="center" wrap="wrap" mb={{ base: "5px", md: "5px" }}>
                    <Button
                        m="4px"
                        color="primary.200"
                        h={{ base: "45px", md: "45px" }}
                        style={{
                            borderRadius: "1rem",
                            borderColor: myConnector === metaMask ? (isActivating ? "orange" : "green") : "unset",
                            cursor: isActivating ? "unset" : "pointer",
                            position: "relative",
                        }}
                        disabled={isActivating}
                        onClick={
                            isActivating
                                ? undefined
                                : () => {
                                      setMyConnector(metaMask);
                                      metaMask.activate(desiredChainId === -1 ? undefined : getAddChainParameters(desiredChainId)).catch((err) => {
                                          console.error(`Failed to activate account using MetaMask`, err);
                                          //resetWalletConnector(walletconnect);
                                          // if (err instanceof NoEthereumProviderError) {}
                                      });
                                  }
                        }
                    >
                        <Flex direction="column" justifyContent="center" align="center">
                            <Box w="30px" h="22px">
                                <LogoMM w="30px" px="1" />
                            </Box>
                            <Text color="primary.300" fontSize="13px" fontWeight="semibold">
                                Metamask
                            </Text>
                        </Flex>
                    </Button>

                    <Button
                        m="4px"
                        color="primary.200"
                        h={{ base: "45px", md: "45px" }}
                        style={{
                            borderRadius: "1rem",
                            borderColor: myConnector === walletConnect ? (isActivating ? "orange" : "green") : "unset",
                            cursor: isActivating ? "unset" : "pointer",
                            position: "relative",
                        }}
                        disabled={isActivating}
                        onClick={
                            isActivating
                                ? undefined
                                : () => {
                                      setMyConnector(walletConnect);
                                      walletConnect.activate(desiredChainId === -1 ? undefined : desiredChainId).catch((err) => {
                                          console.error(`Failed to activate account using WalletConnect`, err);
                                          //resetWalletConnector(walletconnect);
                                          // if (err instanceof NoEthereumProviderError) {}
                                      });
                                  }
                        }
                        fontSize="10px"
                    >
                        <Flex direction="column" justifyContent="center" align="center" p="1px">
                            <Box w="30px" h="22px">
                                <LogoWC w="27px" mt="2px" opacity={0.9} />
                            </Box>
                            <Text color="primary.300" fontSize="13px" fontWeight="semibold">
                                WalletConnect
                            </Text>
                        </Flex>
                    </Button>

                    <Button
                        m="4px"
                        color="primary.200"
                        h={{ base: "45px", md: "45px" }}
                        style={{
                            borderRadius: "1rem",
                            borderColor: myConnector === coinbaseWallet ? (isActivating ? "orange" : "green") : "unset",
                            cursor: isActivating ? "unset" : "pointer",
                            position: "relative",
                        }}
                        disabled={isActivating}
                        onClick={
                            isActivating
                                ? undefined
                                : () => {
                                      setMyConnector(coinbaseWallet);
                                      coinbaseWallet.activate(desiredChainId === -1 ? undefined : getAddChainParameters(desiredChainId)).catch((err) => {
                                          console.error(`Failed to activate account using Coinbase`, err);
                                          //resetWalletConnector(walletconnect);
                                          // if (err instanceof NoEthereumProviderError) {}
                                      });
                                  }
                        }
                    >
                        <Flex direction="column" justifyContent="center" align="center">
                            <Box w="30px" h="22px">
                                <LogoCB w="22px" ml="5px" opacity={0.7} />
                            </Box>
                            <Text color="primary.300" fontSize="13px" fontWeight="semibold">
                                Coinbase
                            </Text>
                        </Flex>
                    </Button>
                </Flex>
            </BrowserView>
            <MobileView>
                <Flex direction={{ base: "row", md: "row" }} justify={{ base: "space-around", md: "space-around" }} alignItems="center" wrap="wrap" mb={{ base: "5px", md: "5px" }}>
                    <Button
                        m="4px"
                        color="primary.200"
                        style={{
                            borderRadius: "1rem",
                            borderColor: myConnector === walletConnect ? (isActivating ? "orange" : "green") : "unset",
                            cursor: isActivating ? "unset" : "pointer",
                            position: "relative",
                        }}
                        disabled={isActivating}
                        onClick={
                            isActivating
                                ? undefined
                                : () => {
                                      setMyConnector(walletConnect);
                                      walletConnect.activate(desiredChainId === -1 ? undefined : desiredChainId).catch((err) => {
                                          console.error(`Failed to activate account using WalletConnect`, err);
                                          //resetWalletConnector(walletconnect);
                                          // if (err instanceof NoEthereumProviderError) {}
                                      });
                                  }
                        }
                        fontSize="10px"
                    >
                        <LogoWC w="30px" px="1" />
                        <Text pt="2px" color="primary.300" fontSize="13px" fontWeight="semibold" ml="2px">
                            WalletConnect
                        </Text>
                    </Button>
                </Flex>
            </MobileView>
        </Flex>
    );
}
