export const crewContractAddress = "0x746Db7B1728aF413C4e2b98216C6171B2FC9D00e";

//Crew functions

// addManager(address[_manager]) - nonpayable
// approve(address[to], uint256[tokenId]) - nonpayable
// mint(address[_to]) - nonpayable
// removeManager(address) - nonpayable
// renounceOwnership() - nonpayable
// safeTransferFrom(address[from],address[to],uint256[tokenId]) - nonpayable
// safeTransferFrom(address[from],address[to],uint256[tokenId], bytes[_data]) - nonpayable
// setApprovalForAll(address[operator], bool[approved]) - nonpayable
// setBaseURI(string[baseURI_]) - nonpayable
// transferFrom(address[from],address[to],uint256[tokenId]) - nonpayable
// transferOwnership(address[newOwner]) - nonpayable
// unpause() - nonpayable

// balanceOf(address[owner]) - view
// baseURI() - view
// burn(uint256[tokenId]) - view
// getApproved(uint256[tokenId]) - view
// isApprovedForAll(address[owner], address[operator]) - view
// isManager(address[_manager]) - view
// name() - view
// owner(address) - view
// ownerOf(uint256[tokenId]) - view
// pause() - nonpayable
// paused() - view
// supportsInterface(bytes4[interfaceId]) - view
// symbol() - view
// tokenURI(uint256[tokenId]) - view
// totalSupply() - view