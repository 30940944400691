import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Outlet, Link } from "react-router-dom";

import ConnectButton from "../elements/ConnectButton";
import Logo from "../ui/Logo";

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
    return (
        <Text mb={{ base: isLast ? 0 : 8, sm: 0 }} mr={{ base: 0, sm: isLast ? 0 : 8 }} display="block" {...rest}>
            <Link to={to}>{children}</Link>
        </Text>
    );
};

const CloseIcon = () => (
    <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <title>Close</title>
        <path fill="white" d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z" />
    </svg>
);

const MenuIcon = () => (
    <svg width="22px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="white">
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
);

export default function Header({ accountState, setAccountState, accountExtras, setAccountExtras, props }) {
    const [show, setShow] = React.useState(false);
    const toggleMenu = () => setShow(!show);

    return (
        <Flex direction="column" align="center" maxW={{ xl: "1400px" }} m="0 auto">
            <Flex as="nav" alignItems="center" justify="space-between" wrap="wrap" w="100%" mb={{ base: "32px", md: "0px" }} p={8} bg={["primary.700", "primary.700", "transparent", "transparent"]} color={["primary.200", "primary.200", "black", "black"]} {...props}>
                <Flex align="center">
                    <Link to="/" onClick={() => setShow(false)}>
                        <Logo w={{ base: "50px", md: "80px" }} color={["white", "white", "primary.500", "primary.500"]} py={0} />
                    </Link>
                </Flex>

                <Box display={{ base: "block", md: "block" }}>
                    <ConnectButton accountState={accountState} setAccountState={setAccountState} accountExtras={accountExtras} setAccountExtras={setAccountExtras} />
                </Box>

                <Box display={{ base: "block", md: "none" }} onClick={toggleMenu}>
                    {show ? <CloseIcon /> : <MenuIcon />}
                </Box>

                <Box display={{ base: show ? "block" : "none", md: "block" }} flexBasis={{ base: "100%", md: "auto" }}>
                    <Flex align="center" justify={["center", "space-between", "flex-end", "flex-end"]} direction={["column", "row", "row", "row"]} pt={[4, 4, 0, 0]}>
                        <MenuItem to="/" onClick={toggleMenu}>
                            H.U.B.
                        </MenuItem>
                        <MenuItem to="/crew" onClick={toggleMenu}>
                            CREW
                        </MenuItem>
                        <MenuItem to="/asteroid" onClick={toggleMenu}>
                            PLOTS
                        </MenuItem>
                        <MenuItem to="/associates" isLast onClick={toggleMenu}>
                            ASSOCIATES
                        </MenuItem>
                    </Flex>
                </Box>
            </Flex>
            <Outlet />
        </Flex>
    );
}
