// Identicon.tsx
import Jazzicon from "@metamask/jazzicon";
import React, { useEffect, useRef } from "react";

export default function Identicon( {account} ) {

    const ref = useRef();

    useEffect(() => {
        if (account && ref.current) {
            //console.log( "Identicon: ", accounts );
            ref.current.innerHTML = "";
            ref.current.appendChild( Jazzicon(16, parseInt(account.slice(2, 10), 16)) );
        }
    }, [account]);

    return <div height="1rem" width="1rem" border-radius="1.125rem" background-color="black" ref={ref}></div>

}
